<template>
    <div class="container">
        <div class="pa-20">
            <img class="logo" src="~@/assets/img/logo.png" />
            <div class="flex f-center back-box ma-t-10" @click="$router.go(-1)">
                <img class="back" src="~@/assets/img/back.png" />
                <span>返回</span>
            </div>

            <div class="ma-t-20">
                <van-button type="primary" class="width100" @click="dialogTableVisible = true" size="large"
                    color="#003AA9">添加</van-button>
            </div>
            <div v-for="(item, index) in list" :key="index" class="flex f-between ma-t-20 fon-20 item-box pa-30">
                <div>
                    <div>
                        临时访问人数： {{ item.num }}
                    </div>
                    <div>
                        添加时间： {{ item.createTime }}
                    </div>
                    <div>
                        备注信息：{{ item.note }}
                    </div>
                </div>
                <div>
                    <el-button type="warning" class="width100" size="large"
                        @click="submitDelUser(item.id)">访客离场</el-button>
                </div>
            </div>
        </div>
        <el-dialog v-model="dialogTableVisible">
            <div class="flex fon-18">
                <div class="width30 t-right pa-r-20"> <text class="c-red">*</text> 临时访问人数</div>
                <div class="width70"><el-input v-model="userData.num" placeholder="请输入内容" /></div>
            </div>
            <div class="flex fon-18 ma-t-20">
                <div class="width30 t-right pa-r-20">备注信息</div>
                <div class="width70"><el-input v-model="userData.note" type="textarea" :rows="4" placeholder="请输入内容" />
                </div>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <div class="flex">
                        <van-button @click="dialogTableVisible = false" class="width50">取消</van-button>
                        <van-button type="primary" @click="submitAddUser" class="width50" color="#003AA9">
                            确认
                        </van-button>
                    </div>
                </span>
            </template>
        </el-dialog>

        <el-dialog title="获取权限" v-model="dialogPermissionVisible" :show-close="false" :close-on-click-modal="false">
            <div class="fon-18">
                <div class="flex f-center f-jcenter">
                    <div class="width30 t-right pa-r-20">选择人员获取权限：</div>
                    <div class="width70 flex f-center">
                        <el-select v-model="permissionID" placeholder="请选择">
                            <el-option v-for="item in users" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                        <div>
                            <van-button type="primary" @click="codeSend()" color="#003AA9" class="ma-l-10"
                                style="height:40px;">
                                获取验证码
                            </van-button>
                        </div>
                    </div>
                </div>
                <div class="flex ma-t-20 f-center">
                    <div class="width30 t-right pa-r-20"> 验证码：</div>
                    <div class="width70"><el-input v-model="code" placeholder="请输入内容" /></div>
                </div>
                <div class="flex f-between ma-t-30">
                    <van-button type="default" class="width50" @click="$router.go(-1)">
                        返回
                    </van-button>
                    <van-button type="primary" @click="codeVerify" class="width50" color="#003AA9">
                        确认
                    </van-button>
                </div>
            </div>
        </el-dialog>

    </div>
</template>

<script>
var _this
export default {
    data() {
        return {
            dialogTableVisible: false,
            input: '',
            list: [],
            dialogPermissionVisible: false,
            users: [],//有权限的保安人员列表
            permissionID: '',//选择的保安id
            code: '',//验证码
            userData: {}
        }
    },
    created() {
        _this = this
        _this.loadDataUsers()
    },
    methods: {
        //获取保安列表
        loadDataUsers() {
            _this.$request({
                url: "/security/green/users",
                method: "GET",
            }).then((result) => {
                _this.users = result;
                _this.dialogPermissionVisible = true
            });
        },
        //发送验证码
        codeSend() {
            if (_this.$validator.isEmpty(_this.permissionID)) {
                return _this.$message('请选择人员');
            }
            _this.$request({
                url: "/security/green/send",
                method: "GET",
                params: {
                    id: _this.permissionID
                },
                isOriginal: true
            }).then((result) => {
                console.log(result)
                _this.$message(result.msg);

            });
        },
        //验证验证码
        codeVerify() {
            if (_this.$validator.isEmpty(_this.permissionID)) {
                return _this.$message('请选择人员');
            }
            if (_this.$validator.isEmpty(_this.code)) {
                return _this.$message('请输入验证码');
            }
            _this.$request({
                url: "/security/green/valid",
                method: "GET",
                params: {
                    id: _this.permissionID,
                    code: _this.code
                }
            }).then((result) => {
                //进入到这里，表示验证码正确
                _this.dialogPermissionVisible = false
                _this.$toast.success({
                        duration: '1500',
                        message: '验证成功',
                        onClose() {
                        }
                    })
                _this.loadDataList()
            }).catch(err => {
                console.log(err)
                // _this.$message('验证码错误');

            })
        },
        //获取访问列表
        loadDataList() {
            _this.$request({
                url: "/security/green/list",
                method: "GET",
                params: {
                    id: _this.permissionID
                }
            }).then((result) => {
                _this.list = result
            });
        },
        //添加人员
        submitAddUser() {
            if (_this.$validator.isEmpty(_this.userData.num)) {
                return _this.$message('请输入人数');
            }
            _this.$request({
                url: "/security/green/add",
                method: "POST",
                data: {
                    userId: _this.permissionID,
                    num: _this.userData.num,
                    note: _this.userData.note,
                    entranceId: _this.$store.state.entranceId
                }
            }).then((result) => {
                _this.dialogTableVisible = false
                _this.userData = {}
                _this.loadDataList()
            });
        },
        //访客离场
        submitDelUser(id) {
            _this.$confirm('是否确认离场?', {
                type: 'success',
                buttonSize: 'large',
                customClass: 'ElMessageBox',
            })
                .then(() => {
                    _this.$request({
                        url: "/security/green/leave",
                        method: "POST",
                        data: {
                            id: id,
                            userId: _this.permissionID,
                            entranceId: _this.$store.state.entranceId
                        }
                    }).then((result) => {
                        _this.$toast.success('操作成功')
                        _this.loadDataList()
                    });
                })
                .catch(() => {
                    // catch error
                })

        }

    }
}
</script>

<style lang="less" scoped>
.item-box {
    background: #eaeef7;
}

.c-red {
    color: red;
}
</style>